<template>
  <div class="footballDraw">
    <!-- banner -->
    <div class="banner">
      <img src="https://activity-resource.321mh.com/nft/sgsd/footballBanner.png" alt="" />
    </div>

    <div class="container">
      <!-- tabs -->
      <div class="tabs">
        <div class="tabsBox">
          <div
            v-for="(item, index) in tabsList"
            :key="index"
            :class="tabIndex == item.value ? 'activeTab' : ''"
            @click="tabHandle(item.value)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>

      <!-- 转盘 -->
      <div class="luckyBox">
        <div class="ruleBtn" @click="showRule">活动<br />规则</div>
        <LuckyWheel
          class="luckyDraw"
          ref="myLucky"
          width="8.5rem"
          height="8.5rem"
          :prizes="prizes"
          :blocks="blocks"
          :buttons="buttons"
          :default-config="defaultConfig"
          :default-style="defaultStyle"
          @end="endCallback"
        />

        <img class="lightBg" src="../../imgs/point/lightBg.png" alt="" />

        <img class="lightLeft" src="../../imgs/point/bg_football_left.png" alt="" srcset="" />
        <img class="lightRight" src="../../imgs/point/bg_football_right.png" alt="" srcset="" />
      </div>

      <div class="count">
        <div v-if="tabIndex == 1">我的球币：{{ lowData.number }}</div>
        <div v-if="tabIndex == 2">您有{{ highData.number }}次抽奖机会</div>
      </div>

      <!-- 按钮 -->
      <div class="flexBox">
        <div class="luckyBtnBox">
          <div class="luckyBtn" @click="startCallback">
            <div class="drawTimes">抽 1 次</div>
            <div v-if="tabIndex == 1" class="cost">{{ lowData.every_time }}球币</div>
          </div>
        </div>

        <div class="luckyBtnBox">
          <div class="luckyBtn" @click="fiveStartCallback">
            <div class="drawTimes">抽 5 次</div>
            <div v-if="tabIndex == 1" class="cost">{{ lowData.every_time * 5 }}球币</div>
          </div>
        </div>
      </div>

      <!-- 中奖记录 -->
      <div class="record">
        <div class="title">
          <img src="https://activity-resource.321mh.com/nft/sgsd/icon_zqbcj_btzs@2x.png" alt="" />
        </div>
        <div class="adress" v-if="tabIndex == 2 && canAddr" @click="addressHandle">
          我的收货地址
        </div>
        <div class="adress adressGrey" v-if="tabIndex == 2 && !canAddr">我的收货地址</div>
        <div class="recordList">
          <div class="recordItem" v-for="(item, index) in logList" :key="index">
            <div>{{ item.name }}</div>
            <div>{{ getTime(item.updated_time) }}</div>
          </div>
        </div>
      </div>

      <van-popup class="luckyvantPop" v-model:show="luckyPop">
        <div class="luckyPopBg" :class="luckyData.length > 1 ? 'luckyPopBgLong' : ''">
          <van-icon class="closeIcon" size="0.6rem" @click="luckyPop = false" name="close" />
          <img class="popHead" src="../../imgs/point/footballPopHead.png" alt="" srcset="" />
          <div class="luckyPopBox">
            <div
              class="luckyItem"
              :style="luckyData.length > 1 ? '' : 'width:100%;'"
              v-for="item in luckyData"
              :key="item"
            >
              <img class="luckyIcon" :src="item.image_icon" alt="" srcset="" />
              <div class="luckyName">{{ item.award_name }}</div>
            </div>
          </div>
        </div>
        <div class="againBtn" @click="drawAgain(luckyData.length)">
          {{ luckyData.length > 1 ? '再抽5次' : '再抽1次' }}
        </div>
      </van-popup>

      <!-- 初级活动规则 -->
      <van-popup class="vantPop" v-model:show="lowRulePop">
        <div class="rulePopBox">
          <div class="rulePop">
            <div class="mengTop"></div>
            <div class="mengBottom"></div>
            <div class="ruleCentent">
              <div class="head">初级奖池活动规则</div>
              <div class="ruleItem">
                <div>1、活动时间：2022年11月20日-2022年12月27日14:00。</div>
                <div>2、世界杯每场比赛开场时间，会对持有球队藏品的用户快照。</div>
                <div>
                  3、世界杯每场比赛结束后，持有比赛获胜队伍藏品的用户，在次日10：00可获得1000枚球币。
                </div>
                <div>4、获得现金红包，可以在我的-“我的红包”里面查看。</div>
                <div>
                  5、获得积分，可以在“我的”-“积分”里面查看，积分可以用于积分商城抽奖及兑换。
                </div>
                <div>6、活动结束后，单位用户未消耗的球币将自动失效并进行清零。</div>
              </div>

              <div class="ruleItem">
                <div class="title">奖励设置:</div>
                <div>
                  球币通过持有对应世界杯比赛获胜球队获得，每1次抽奖消耗1000枚球币，中奖率为100%，奖品类型如下：
                </div>
                <div>2元现金红包、5元现金红包、10元现金红包、20元现金红包、666元现金红包</div>
                <div>200积分、500积分、1000积分、1500积分</div>
              </div>
            </div>
          </div>
        </div>
      </van-popup>

      <!-- 高级活动规则 -->
      <van-popup class="vantPop" v-model:show="highRulePop">
        <div class="rulePopBox">
          <div class="rulePop">
            <div class="mengTop"></div>
            <div class="mengBottom"></div>
            <div class="ruleCentent">
              <div class="head">高级奖池活动规则</div>
              <!-- <div class="ruleItem">
                <div class="title">活动描述:</div>
                <div>
                  用户持有指定藏品获得对应的抽奖机会，抽奖机会只限于高级抽奖活动中进行抽奖。<br />
                  持有“世界杯纪念徽章”藏品的用户，可以获得1次抽奖机会。<br />
                  持有“世界杯16强纪念徽章”16强藏品的用户，可以已获得2次抽奖机会。<br />
                  持有”铜奖杯“藏品的用户，可以已获得3次抽奖机会。<br />
                  持有”银奖杯“藏品的用户，可以获得4次抽奖机会。<br />
                  持有”金奖杯“藏品的用户，可以获得5次抽奖机会。<br />
                  持有多个藏品，抽奖机会可以叠加。
                </div>
                <div style="font-size: 10px; margin-top: 10px">
                  (以上藏品均来自合成活动，详情请看足球杯公告)
                </div>
              </div> -->
              <div class="ruleItem">
                <div class="title">活动规则:</div>
                <div>1、2022年12月23日14:00对持有指定藏品的用户进行快照空投抽奖次数。</div>
                <div>2、高级奖池开放时间：2022年12月23日16:00-2022年12月26日23:59。</div>
                <div>3、抽奖机会根据快照时间用户持有的藏品进行空投。</div>
                <div>
                  4、单位用户获得实物奖励，需要填写邮寄信息（姓名、联系方式、地址），会在活动结束后统一时间进行邮寄。如因为疫情无法邮寄，将会延后。
                </div>
                <div>5、活动结束后，单个用户未消耗的抽奖机会，将自动失效并进行清零。</div>
                <div style="margin-top: 10px">
                  抽中实物奖励的用户，请填写“我的收货信息”，平台会根据收货信息在活动结束后进行邮寄。
                </div>
                <div>*活动最终解释权归看漫数藏所有</div>
              </div>

              <!-- <div class="ruleItem">
                <div class="title">奖励设置:</div>
                <div>
                  用户消耗一次抽奖机会，将有机会获得以下奖品，当未获得以下奖品时将会显示“再接再厉”。
                  <img
                    class=""
                    src="https://activity-resource.321mh.com/nft/sgsd/pic_gjjc_gz.png"
                    alt=""
                  />
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </van-popup>

      <!-- 地址填写 @confirm="" -->
      <addAddr ref="addAddr" v-model:show.sync="addrShow" @confirm="closeAddr" />
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, ref, inject } from 'vue';
import { useRouter } from 'vue-router';
import { getfootballawardlist, drawfootball, getawardlog } from '@/service/point';
import { nftUtils } from '@/utils';
import moment from 'moment';
import addAddr from './components/addr.vue';

export default {
  name: 'footballDraw',
  components: { addAddr },
  setup() {
    const router = useRouter();
    const addAddr = ref('addAddr');
    const toastFn = inject('$toast');
    const state = reactive({
      isLogin: nftUtils.isLogin(),
      rulePop: false,
      myLucky: null,
      addrShow: false, //地址填写弹窗
      tabIndex: 1,
      tabsList: [
        { name: '初级奖池', value: 1 },
        { name: '高级奖池', value: 2 }
      ],
      blocks: [
        {
          padding: '0.7rem',
          imgs: [
            {
              src: 'https://activity-resource.321mh.com/nft/sgsd/footballBg.png', // 转盘底图
              width: '100%',
              rotate: true
            }
          ]
        }
      ],
      prizes: [],

      buttons: [
        {
          radius: '0.85rem',
          pointer: true,
          imgs: [
            { src: require('../../imgs/point/goIcon_football.png'), width: '100%', top: '-1.43rem' }
          ] //这里的top是指针高度rem+圆半径rem得到的
        }
      ],

      defaultConfig: {
        gutter: '0.03rem',
        accelerationTime: 1500,
        decelerationTime: 1500
      },

      defaultStyle: {
        fontSize: '0.28rem',
        fontColor: '#FFFFFF',
        background: '#006066'
      },

      oneTime: 0,
      fiveTime: 0,
      number: 0,
      luckyData: [],

      logList: [],
      luckyData: [],

      luckyPop: false,
      lowRulePop: false,
      highRulePop: false,

      canAddr: false,

      flag: false,

      lowData: [],
      highData: [],
      lowPrizes: [],
      highPrizes: []
    });

    onMounted(() => {
      getList();
      getlog();
    });

    const showRule = () => {
      if (state.tabIndex == 1) {
        state.lowRulePop = true;
      } else {
        state.highRulePop = true;
      }
    };

    const closeAddr = () => {
      state.addrShow = false;
    };

    const getList = () => {
      state.prizes = [];
      state.lowPrizes = [];
      state.highPrizes = [];
      getfootballawardlist({}).then(res => {
        console.log(res);
        if (res.status == 0) {
          // const configData = res.data.low_level_award.items;
          state.lowData = res.data.low_level_award;
          state.highData = res.data.high_level_award;
          // state.oneTime = res.data[type].every_time;
          // state.fiveTime = res.data[type].every_time * 5;
          // state.number = res.data[type].number;
          let itemObj = {};
          state.lowData.items.forEach(item => {
            itemObj = {
              fonts: [{ text: item.award_name, top: '0.10rem' }],
              imgs: [
                {
                  src: item.image_icon,
                  width: '40%',
                  top: '30%'
                }
              ],
              id: item.id
            };
            state.lowPrizes.push(itemObj);
          });

          state.highData.items.forEach(item => {
            itemObj = {
              fonts: [{ text: item.award_name, top: '0.10rem' }],
              imgs: [
                {
                  src: item.image_icon,
                  width: '40%',
                  top: '36%'
                }
              ],
              id: item.id
            };
            state.highPrizes.push(itemObj);
          });

          if (state.tabIndex == 1) {
            state.prizes = state.lowPrizes;
          } else {
            state.prizes = state.highPrizes;
          }
        }
      });
    };

    const getlog = () => {
      state.canAddr = false;
      getawardlog({}).then(res => {
        console.log(res);
        state.logList = res.data;
        for (let i in state.logList) {
          if (state.logList[i].type == 3) {
            state.canAddr = true;
          }
        }
      });
    };

    // 抽奖结束会触发end回调
    const endCallback = prize => {
      console.log(prize);
    };

    const tabHandle = value => {
      state.tabIndex = value;
      if (state.tabIndex == 1) {
        // getList('low_level_award');
        state.prizes = state.lowPrizes;
      } else {
        state.prizes = state.highPrizes;
      }
    };

    const getScore = () => {
      state.flag = false;
      getList();
      getlog();
    };

    // 点击抽奖按钮会触发star回调
    const startCallback = () => {
      if (!state.isLogin) {
        router.push({ path: '/login' });
        return;
      }

      if (state.flag) {
        return;
      }

      state.flag = true;
      // 模拟调用接口异步抽奖

      drawfootball({
        type: state.tabIndex,
        number: 1
      })
        .then(res => {
          state.myLucky.play();
          if (res.status == 0) {
            // 假设后端返回的中奖索引是0
            //   const index = 0;
            //   // 调用stop停止旋转并传递中奖索引
            state.luckyData = res.data;

            state.myLucky.stop(luckyIndex(state.luckyData[0].id));

            setTimeout(() => {
              state.luckyPop = true;
              state.flag = false;
              getScore();
            }, 3000);
          } else {
            toastFn({ message: res.message, duration: 3000 });
            // getScore();
            state.flag = false;
            state.myLucky.stop();
          }
        })
        .catch(() => {
          setTimeout(() => {
            state.myLucky.stop(0);
          });
        });
    };

    // 遍历出中奖索引
    const luckyIndex = id => {
      for (let i in state.prizes) {
        if (state.prizes[i].id === id) {
          // alert(1);
          return i;
        }
      }
    };

    const drawAgain = num => {
      state.luckyPop = false;
      if (num > 1) {
        fiveStartCallback();
      } else {
        startCallback();
      }
    };

    const fiveStartCallback = () => {
      if (!state.isLogin) {
        router.push({ path: '/login' });
        return;
      }

      if (state.flag) {
        return;
      }

      state.flag = true;

      drawfootball({
        type: state.tabIndex,
        number: 5
      }).then(res => {
        console.log(res);
        if (res.status == 0) {
          state.luckyData = res.data;
          state.luckyPop = true;
          getScore();
        } else {
          state.flag = false;
          toastFn({ message: res.message, duration: 3000 });
        }
      });
    };

    // 获取格式时间
    const getTime = (time, format = 'YYYY-MM-DD HH:mm') => {
      return moment(time).format(format);
    };

    const addressHandle = () => {
      addAddr.value.show();
    };

    return {
      ...toRefs(state),
      tabHandle,
      getTime,
      closeAddr,
      addressHandle,
      startCallback,
      drawAgain,
      fiveStartCallback,
      getScore,
      showRule,
      endCallback,
      addAddr
    };
  }
};
</script>

<style lang="scss" scoped>
.footballDraw {
  background: linear-gradient(180deg, #00533a 0%, #122043 100%);
}
.banner {
  font-size: 0;
  img {
    width: 100%;
  }
}
.container {
  background: linear-gradient(180deg, #00533a 0%, #122043 100%);
  padding-bottom: 70px;
}
.tabs {
  padding-top: 4px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;

  .tabsBox {
    display: flex;
    justify-content: center;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 8px;

    > div {
      width: 94px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      white-space: nowrap;
      // opacity: 0.3;
      border-radius: 8px;
    }
  }

  .activeTab {
    opacity: 1;
    // border: 1px solid;

    background-image: linear-gradient(#a8d4bf, #089953, #00a0aa);
    // border-image: linear-gradient(180deg, rgba(179, 255, 119, 0.56), rgba(0, 118, 125, 1)) 1 1;
  }
}
.luckyBox {
  position: relative;
  // margin-top: 28px;
  margin-top: 12px;
  height: 388px;

  .ruleBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 20px 20px 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 50px;
    height: 44px;
    background: #58dedd;
    opacity: 1;
    font-size: 12px;
    font-weight: bold;
    color: #000000;
  }

  .luckyDraw {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 900;
  }

  .lightBg {
    width: 100%;
  }

  .lightLeft {
    width: 155px;
    position: absolute;
    left: 0;
    top: -74px;
    z-index: 500;
  }

  .lightRight {
    width: 178px;
    position: absolute;
    right: 0;
    bottom: -75px;
    z-index: 500;
  }
}

.count {
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  margin-bottom: 16px;
}

.flexBox {
  display: flex;
  justify-content: space-between;
  padding: 0 54px;

  .luckyBtnBox {
    border-radius: 12px;
    padding: 2px;
    background: linear-gradient(180deg, rgba(255, 238, 194, 1), rgba(231, 111, 0, 1));
  }
  .luckyBtn {
    width: 117px;
    height: 48px;
    background: linear-gradient(180deg, #ffcb46 0%, #ffb800 100%);
    border-radius: 12px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .drawTimes {
      font-size: 16px;
      font-weight: bold;
      // line-height: 28px;
      color: #1e0071;
    }

    .cost {
      font-size: 12px;
      font-weight: 400;
      // line-height: 20px;
      color: rgba(30, 0, 113, 0.7);
    }
  }

  .disLuckyBtn {
    opacity: 0.5;
  }
}

.record {
  width: 343px;
  background: #146350;
  margin: 0 auto;
  margin-top: 32px;
  padding-bottom: 24px;
  border-radius: 4px;
  .title {
    padding-top: 16px;
    text-align: center;
    img {
      width: 274px;
    }
  }

  .adress {
    font-size: 12px;
    font-weight: 500;
    color: #3cdbe8;
    padding: 8px;
    text-align: right;
  }

  .adressGrey {
    opacity: 0.5;
  }

  .recordList {
    width: 327px;
    margin: 0 auto;
    background: #094f42;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #3eb598;

    .recordItem {
      margin: 0 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 42px;
      font-size: 12px;
      font-weight: 600;
      color: #ffffff;
      border-bottom: 1px solid #3eb598;

      :last-child {
        font-weight: 400;
      }
    }

    .recordItem:last-child {
      border-bottom: none;
    }
  }
}
.luckyPopBg {
  margin: 60px 0 40px 0;
  position: relative;
  width: 235px;
  // height: 153px;
  padding: 9px;
  background: linear-gradient(159deg, #4ce367 0%, #3fcbe9 100%);
  border-radius: 8px 8px 8px 8px;

  .closeIcon {
    position: absolute;
    top: -60px;
    right: 0;
  }

  .luckyPopBox {
    padding: 40px 0 20px 0;
    width: 100%;
    height: 100%;
    border-radius: 8px 8px 8px 8px;
    background: linear-gradient(180deg, #00986a 0%, #00459c 100%);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .luckyItem {
      margin-top: 12px;
      font-size: 14px;
      font-weight: 600;
      color: #ffffff;
      line-height: 20px;
      width: 33.3%;
      height: 110px;
      text-align: center;
    }

    .luckyName {
      overflow: hidden;
      text-overflow: ellipsis;

      display: -webkit-box;

      -webkit-box-orient: vertical;

      -webkit-line-clamp: 2;
      // white-space: nowrap;
    }
  }

  .popHead {
    width: 198px;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
  }

  .luckyIcon {
    width: 40px;
  }
}
.againBtn {
  // position: absolute;
  // top: 100%;
  // left: 50%;
  // transform: translateX(-50%);
  margin: 0 auto;
  width: 60px;
  padding: 0 26px;
  // margin: 10px 0;
  height: 34px;
  line-height: 34px;
  border-radius: 29px 29px 29px 29px;
  opacity: 1;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  border: 1px solid #ffffff;
}

.luckyPopBgLong {
  width: 274px;
  // height: auto;
}
.rulePopBox {
  position: relative;
  width: 300px;
  max-height: 400px;
  background: #2f293f;
  border-radius: 8px 8px 8px 8px;

  .rulePop {
    overflow-y: auto;
    max-height: 400px;
  }

  .mengTop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background: linear-gradient(0deg, rgba(47, 41, 63, 0) 0%, #2f293f 100%);
    // background: linear-gradient(180deg, #2f293f 100%, rgba(47, 41, 63, 0) 0%);
  }

  .mengBottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background: linear-gradient(180deg, rgba(47, 41, 63, 0) 0%, #2f293f 100%);
  }

  .ruleCentent {
    padding: 15px 20px;
  }

  .head {
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 20px;
    text-align: center;
  }

  .ruleItem {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);
    line-height: 26px;
    margin-top: 15px;
    margin-bottom: 10px;

    .title {
      font-weight: 500;
      font-size: 16px;
    }

    img {
      margin-top: 12px;
      width: 100%;
    }
  }
}
</style>
<style lang="scss">
.footballDraw {
  .vantPop {
    background: #2f293f !important;
    border-radius: 8px;
  }
  .luckyvantPop {
    background: transparent !important;
  }
}
</style>
