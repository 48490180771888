<template>
  <div>
    <van-popup
      class="vantPop"
      closeable
      v-model:show="showPop"
      :close-on-click-overlay="false"
      close-icon-position="top-right"
      @close="closeHandle"
    >
      <div class="addrBox">
        <div class="title">收货信息</div>
        <div class="form">
          <div class="form-item">
            <van-field
              required
              label-width="80px"
              label="收件人"
              maxlength="20"
              v-model="subForm.name"
              class="field"
              placeholder="请输入收件人"
            />
          </div>
          <div class="form-item">
            <van-field
              label="电话"
              label-width="80px"
              v-model="subForm.mobile"
              class="field"
              required
              maxlength="11"
              placeholder="请输入收件人电话"
            />
          </div>
          <div class="form-item">
            <van-field
              required
              label-width="80px"
              label="地区"
              readonly
              name="area"
              v-model="subForm.province_addr"
              class="field"
              right-icon="arrow-down"
              @click="showArea = true"
              placeholder="请选择地区"
            />
          </div>
          <div class="form-item">
            <van-field
              :rows="3"
              required
              label-width="80px"
              type="textarea"
              autosize
              maxlength="100"
              label="详细地址"
              v-model="subForm.addr"
              class="field"
              placeholder="请输入详细地址"
            />
          </div>
        </div>
        <div class="btn" @click="submit">提交信息</div>
      </div>
    </van-popup>

    <!--  -->
    <van-popup v-model:show="showArea" position="bottom">
      <van-area
        :value="subForm.area_code"
        :area-list="areaList"
        @confirm="onConfirm"
        @cancel="showArea = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { reactive, toRefs, computed, watch } from 'vue';
import { areaList } from '@vant/area-data';
import { Toast } from 'vant';
import { getuseraddr, addoreditaddr } from '@/service/point';

export default {
  props: {
    // 是否显示
    show: {
      type: Boolean,
      default: false
    },
    order_id: {
      type: Number,
      default: 0
    }
  },
  setup(props, ctx) {
    const state = reactive({
      show: props.show,
      showArea: false,
      subForm: {
        name: '',
        mobile: '',
        area_code: '',
        province_addr: '',
        addr: ''
      }
    });

    const show = () => {
      state.show = true;
      getuseraddr({}).then(res => {
        console.log(res);
        let info = res.data;
        state.subForm = {
          name: info.name,
          mobile: info.mobile,
          area_code: info.area_code,
          province_addr: info.province_addr,
          addr: info.addr
        };
      });
    };
    const closeHandle = () => {
      state.show = false;
    };

    const showPop = computed({
      get: () => {
        return state.show;
      },
      set: v => {
        ctx.emit('update:show', v);
      }
    });

    watch(
      () => props.show,
      show => {
        state.show = show;
      }
    );

    const onConfirm = val => {
      console.log(val.length);
      let str = '';
      val.map(i => {
        str += i.name;
      });
      state.subForm.province_addr = str;
      state.subForm.area_code = val[val.length - 1].code;
      state.showArea = false;
      console.log(state.subForm);
    };
    const submit = async () => {
      if (
        !state.subForm.name ||
        !state.subForm.mobile ||
        !state.subForm.province_addr ||
        !state.subForm.addr
      ) {
        Toast('请完善信息');
        return;
      }
      let reg = /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/;

      if (!reg.test(state.subForm.mobile)) {
        Toast('请填写正确手机号');
        return;
      }

      const { status, message } = await addoreditaddr({
        ...state.subForm
      });
      if (status == 0) {
        Toast('提交成功');
        // ctx.emit('confirm');
        state.show = false;
      } else {
        Toast(message);
      }
    };

    return {
      ...toRefs(state),
      areaList,
      submit,
      onConfirm,
      show,
      showPop,
      closeHandle
    };
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixin.scss';
.addrBox {
  width: 300px;

  background: #2f293f;
  border-radius: 8px 8px 8px 8px;
  padding: 19px 20px;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  .close {
    position: absolute;
    top: 12px;
    right: 12px;
  }
  .title {
    font-size: 18px;
    font-weight: 500;
  }
  .field {
    // width: 255px;
    min-height: 44px;
    background-color: #1c172a;

    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
    --van-field-label-color: #fff;
    --van-field-input-text-color: #fff;
    --van-field-placeholder-text-color: #6d6979;
    --van-field-input-disabled-text-color: #fff;
    --van-field-disabled-text-color: #fff;
    --van-cell-border-color: $primaryc;
  }
  .form {
    margin: 21px 0;
    .form-item {
      margin-bottom: 12px;
    }
  }
  .btn {
    width: 138px;
    height: 36px;
    background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
    border-radius: 8px 8px 8px 8px;
    color: #1c172a;
    font-weight: 600;
    font-size: 16px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
